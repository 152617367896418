import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import './custom.scss';
import SearchComponent from './SearchComponent';
import DetailComponent from './DetailComponent';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      hasMore: false,
      searching: false,
      formValues: {},
      noResults: false,
      kioskMode: false,
      localCemeteryID: undefined,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('kiosk') === 'true') {
      this.state.kioskMode = true;
    }
    const localCemetery = urlParams.get('localcemetery');
    if (localCemetery) {
      const localCemeteryID = parseInt(localCemetery, 10);
      if (localCemeteryID > 0 && localCemeteryID <= 5) {
        this.state.localCemeteryID = localCemeteryID;
      }
    }
  }

  handleSearchChange(e) {
    this.setState(e);
  }

  render() {
    return (
      <div className="App">
        <div>
          <img src="/logo.png" alt="Logo" style={{ width: '80%', maxWidth: '400px', margin: '20px 0 20px 0' }} />
          <h4>Verstorbenensuche</h4>
        </div>
        <HashRouter>
          <Switch>
            <Route path="/" exact>
              <SearchComponent
                handleSearchChange={this.handleSearchChange}
                {...this.state}
              />
            </Route>
            <Route path="/:id" render={(props) => <DetailComponent id={props.match.params.id} {...this.state} />} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
