export const cemeteries = {
  '': 'Alle',
  '001': 'Friedhof Hörnli',
  '002': 'Friedhof Wolfgottesacker',
  '004': 'Friedhof Bettingen',
  '005': 'Israelitischer Friedhof',
};

export const cemeteryEntryCoordinates = {
  '001': [47.56590, 7.63683],
  '002': [47.53937, 7.60717],
  '004': [47.57177, 7.66255],
  '005': [47.56712, 7.55899],
};

export const cemeteryInitialZoomLevels = {
  '001': 15,
  '002': 15,
  '004': 18,
  '005': 17,
};

export const cemeteryBoundaries = {
  '001': [
    [7.636845339159828, 47.568247242731594],
    [7.63655435448388, 47.565036982038286],
    [7.637431131066724, 47.564972531818874],
    [7.637349291198789, 47.56440606718743],
    [7.636842111333566, 47.563804352592896],
    [7.63852753024256, 47.56323486555677],
    [7.639693718702581, 47.5624054338782],
    [7.640504045173745, 47.56235006876445],
    [7.640504624050728, 47.56250296114673],
    [7.640982868558378, 47.562475149419846],
    [7.64099666799139, 47.562610031351475],
    [7.643800006260011, 47.56252417934814],
    [7.643721882996573, 47.56553118892195],
    [7.643974358246432, 47.56553074372086],
    [7.644126600048904, 47.5645202102926],
    [7.644537518135834, 47.56425866599171],
    [7.644443496278476, 47.56399801430691],
    [7.645587153674014, 47.56422982290099],
    [7.645920084165191, 47.564418098543186],
    [7.6460641653783465, 47.564735590049466],
    [7.646942665063792, 47.56511625399157],
    [7.647681616395288, 47.565488168294834],
    [7.647929389491385, 47.56598237752423],
    [7.647876836521635, 47.56613536525532],
    [7.6471865827529015, 47.56632547243053],
    [7.646057158873728, 47.566349975303346],
    [7.644980400099122, 47.56624846175159],
    [7.6443756066005175, 47.566204563942726],
    [7.644136728514934, 47.5662859294589],
    [7.644036649981172, 47.56617818152797],
    [7.644021611814273, 47.5657240252994],
    [7.643749116806597, 47.56570202159084],
    [7.644114866378492, 47.567509113321904],
    [7.644624770657479, 47.5687853189175],
    [7.641656240470122, 47.56919974869832],
    [7.641337028709554, 47.5691283554042],
    [7.640890701686892, 47.568827842645376],
    [7.640391189991767, 47.56851842663684],
    [7.639646501759506, 47.56838480945613],
    [7.6374941379023396, 47.568505424752246],
    [7.637479725298921, 47.568204160140226],
    [7.636845339159828, 47.568247242731594],
  ],
  '002': [
    [7.607592041438016, 47.53906588424915],
    [7.6080330116713935, 47.53896765185707],
    [7.608089031048901, 47.53904221884075],
    [7.608514467209111, 47.539175606434924],
    [7.608709467109145, 47.53951708593563],
    [7.608895054149936, 47.539509178965915],
    [7.609057869467015, 47.539544915937896],
    [7.60918430401127, 47.539625674945],
    [7.609254527351411, 47.53967637799798],
    [7.609314908396315, 47.53976427577669],
    [7.609370262012891, 47.53983749403035],
    [7.6104066742148335, 47.539558511051915],
    [7.610756508694993, 47.54013314745356],
    [7.610353953281038, 47.54051058174622],
    [7.609296940877392, 47.54079139308574],
    [7.606505761610345, 47.541544786047595],
    [7.605957375163838, 47.54075142385924],
    [7.606935248059393, 47.54042983569602],
    [7.607017266832203, 47.54032538915029],
    [7.606978111345187, 47.54012308546939],
    [7.607085169188711, 47.53995654549767],
    [7.607147275652313, 47.539855725162745],
    [7.606544274128539, 47.53943118987121],
    [7.607024110545453, 47.53954741625684],
    [7.607210371348013, 47.53922696768986],
    [7.607315045958996, 47.539147670707],
    [7.607592041438016, 47.53906588424915],
  ],
  '004': [
    [7.6624082327923775, 47.57171587866443],
    [7.662578351333064, 47.57187608897493],
    [7.662099587060186, 47.572114443305175],
    [7.6619214953380625, 47.571954247608694],
    [7.6624082327923775, 47.57171587866443],
  ],
  '005': [
    [7.557605009254386, 47.5656461162198],
    [7.557804821999711, 47.56526907646673],
    [7.5583645766144825, 47.56541330243617],
    [7.55837663260872, 47.5654564598324],
    [7.558513734702801, 47.565560646071965],
    [7.55837663260872, 47.5654564598324],
    [7.558513734702801, 47.565560646071965],
    [7.558569559294376, 47.56556688433583],
    [7.559620883172356, 47.568015682380974],
    [7.558760151214896, 47.56818745116825],
    [7.557605009254386, 47.5656461162198],
  ],
};
