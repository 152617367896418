import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import * as QRCode from 'qrcode.react';
import {
  MapContainer, Marker, TileLayer, MapConsumer, Polyline, Polygon,
} from 'react-leaflet';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import leafletIcon from 'leaflet/dist/images/marker-icon.png';
import leafletShadow from 'leaflet/dist/images/marker-shadow.png';
import Locate from 'leaflet.locatecontrol';
import '@fortawesome/fontawesome-free/css/all.min.css';
import centroid from '@turf/centroid';
import { point, polygon } from '@turf/helpers';
import distance from '@turf/distance';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import PropTypes from 'prop-types';
import {
  EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon,
} from 'react-share';
import romanLetters from './RomanLetters';
import {
  cemeteries, cemeteryEntryCoordinates, cemeteryInitialZoomLevels, cemeteryBoundaries,
} from './Cemeteries';

const getWFSGraveURLsFromGraveData = (gravedata) => {
  if (!gravedata.cemetery || !gravedata.section || !gravedata.gravenumber) {
    return [null, null, null];
  }
  let section = '00';
  let area = '00';
  if (gravedata.section.length >= 4) {
    area = gravedata.section.substring(0, 2).trim().replace(/^0+/, '');
    section = gravedata.section.substring(2).trim().replace(/^0+/, '');
  }
  if (area === '') {
    area = '0';
  }
  const saneGraveNumber = gravedata.gravenumber.replace(/^0+/, '');
  const smallURL = `https://wfs.geo.bs.ch/?SERVICE=WFS&VERSION=2.0.0&REQUEST=getfeature&TYPENAME=FE_Graeber&featureid=FE_Graeber.${gravedata.cemetery}_${area.toLowerCase()}_${section.toLowerCase()}_${saneGraveNumber}&propertyname=the_geom&SRSNAME=EPSG:4326&outputformat=geojson`;
  const bigURL = `https://wfs.geo.bs.ch/?SERVICE=WFS&VERSION=2.0.0&REQUEST=getfeature&TYPENAME=FE_Graeber&featureid=FE_Graeber.${gravedata.cemetery}_${area.toUpperCase()}_${section.toUpperCase()}_${saneGraveNumber}&propertyname=the_geom&SRSNAME=EPSG:4326&outputformat=geojson`;
  const romanURL = `https://wfs.geo.bs.ch/?SERVICE=WFS&VERSION=2.0.0&REQUEST=getfeature&TYPENAME=FE_Graeber&featureid=FE_Graeber.${gravedata.cemetery}_${area.toLowerCase()}_${romanLetters[section] || '0'}_${saneGraveNumber}&propertyname=the_geom&SRSNAME=EPSG:4326&outputformat=geojson`;
  return [smallURL, bigURL, romanURL];
};

const getWFSSectionURLsFromGraveData = (gravedata) => {
  if (!gravedata.cemetery || !gravedata.section) {
    return [null, null, null];
  }
  let section = '00';
  let area = '00';
  if (gravedata.section.length >= 4) {
    area = gravedata.section.substring(0, 2).trim().replace(/^0+/, '');
    section = gravedata.section.substring(2).trim().replace(/^0+/, '');
  }
  if (area === '') {
    area = '0';
  }
  const smallURL = `https://wfs.geo.bs.ch/?SERVICE=WFS&VERSION=2.0.0&REQUEST=getfeature&TYPENAME=FE_Sektionen&featureid=FE_Sektionen.${gravedata.cemetery}_${area.toLowerCase()}_${section.toLowerCase()}&propertyname=the_geom&SRSNAME=EPSG:4326&outputformat=geojson`;
  const bigURL = `https://wfs.geo.bs.ch/?SERVICE=WFS&VERSION=2.0.0&REQUEST=getfeature&TYPENAME=FE_Sektionen&featureid=FE_Sektionen.${gravedata.cemetery}_${area.toUpperCase()}_${section.toUpperCase()}&propertyname=the_geom&SRSNAME=EPSG:4326&outputformat=geojson`;
  const romanURL = `https://wfs.geo.bs.ch/?SERVICE=WFS&VERSION=2.0.0&REQUEST=getfeature&TYPENAME=FE_Sektionen&featureid=FE_Sektionen.${gravedata.cemetery}_${area.toLowerCase()}_${romanLetters[section] || '0'}&propertyname=the_geom&SRSNAME=EPSG:4326&outputformat=geojson`;
  return [smallURL, bigURL, romanURL];
};

export default class DetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      deceased: null,
      sectionGeometry: [],
      graveGeometry: [],
      coordinates: [],
      isLoadingGeoInfo: true,
    };
  }

  componentDidMount() {
    const { id } = this.state;
    const finalCoordinates = [];
    axios.request({
      url: `https://api.verstorbenensuche.cloud.bs.ch/deceased/${id}`,
      method: 'get',
    }).then((res) => {
      this.setState({
        deceased: res.data,
      });
      return res.data;
    }).then((deceased) => {
      finalCoordinates.push(cemeteryEntryCoordinates[deceased.cemetery]);
      this.setState({
        coordinates: finalCoordinates,
      });
      const wfsSectionURLs = getWFSSectionURLsFromGraveData(deceased);
      const sectionPromise = Promise.allSettled([
        axios.request(wfsSectionURLs[0]),
        axios.request(wfsSectionURLs[1]),
        axios.request(wfsSectionURLs[2]),
      ]).then(([res1, res2, res3]) => {
        if (res1.status === 'fulfilled') {
          return res1.value;
        }
        if (res2.status === 'fulfilled') {
          return res2.value;
        }
        if (res3.status === 'fulfilled') {
          return res3.value;
        }
        return null;
      });
      if (deceased.gravenumber) {
        const wfsGraveURLs = getWFSGraveURLsFromGraveData(deceased);
        const gravePromise = Promise.allSettled([
          axios.request(wfsGraveURLs[0]),
          axios.request(wfsGraveURLs[1]),
          axios.request(wfsGraveURLs[2]),
        ]).then(([res1, res2, res3]) => {
          if (res1.status === 'fulfilled') {
            return res1.value;
          }
          if (res2.status === 'fulfilled') {
            return res2.value;
          }
          if (res3.status === 'fulfilled') {
            return res3.value;
          }
          return null;
        });
        return Promise.allSettled([sectionPromise, gravePromise])
          .then(([sectionPromiseResult, gravePromiseResult]) => {
            const resultArr = [null, null];
            if (sectionPromiseResult.status === 'fulfilled') {
              resultArr[0] = sectionPromiseResult.value;
            }
            if (gravePromiseResult.status === 'fulfilled') {
              resultArr[1] = gravePromiseResult.value;
            }
            return resultArr;
          }).catch(() => [null, null]);
      }
      return sectionPromise.then((res) => [res, null]).catch(() => [null, null]);
    }).then(([sectionData, graveData]) => {
      if (graveData) {
        if (graveData.data.features[0].geometry.coordinates[0][0] && graveData.data.features[0]
          .geometry.coordinates[0][0].length === 2) {
          const finalGraveGeometry = graveData.data.features[0]
            .geometry.coordinates.map((coordArr) => coordArr
              .map((coordinate) => coordinate.reverse()));
          this.setState({
            graveGeometry: finalGraveGeometry,
          });
          const gravePolygon = polygon(finalGraveGeometry);
          const graveCentroid = centroid(gravePolygon);
          const newTargetCoords = graveCentroid.geometry.coordinates;
          finalCoordinates.push(newTargetCoords);
        }
      }
      if (sectionData) {
        if (sectionData.data.features[0].geometry.coordinates[0]
          && sectionData.data.features[0].geometry.coordinates[0].length >= 2) {
          const finalSectionCoordinates = sectionData.data.features[0]
            .geometry.coordinates.map((coordArr) => coordArr
              .map((coordinate) => coordinate.reverse()));
          this.setState({
            sectionGeometry: finalSectionCoordinates,
          });
          if (!graveData) {
            const sectionPolygon = polygon(finalSectionCoordinates);
            const sectionCentroid = centroid(sectionPolygon);
            const newTargetCoords = sectionCentroid.geometry.coordinates;
            finalCoordinates.push(newTargetCoords);
          }
        }
      }
      this.setState({
        coordinates: finalCoordinates,
        isLoadingGeoInfo: false,
      });
    })
      .catch(() => {
        this.setState({
          isLoadingGeoInfo: false,
        });
      });
    leaflet.Marker.prototype.options.icon = leaflet.icon({
      iconUrl: leafletIcon,
      shadowUrl: leafletShadow,
      iconSize: [24, 40],
      iconAnchor: [12, 40],
    });
  }

  render() {
    const {
      deceased, coordinates, sectionGeometry, graveGeometry, isLoadingGeoInfo,
    } = this.state;
    const { kioskMode } = this.props;
    return (
      <div>
        {deceased && <DetailCard deceased={deceased} kioskMode={kioskMode} />}
        {coordinates.length > 0
        && (
        <GraveMap
          coordinates={coordinates}
          sectionGeometry={sectionGeometry}
          graveGeometry={graveGeometry}
          initialZoomLevel={cemeteryInitialZoomLevels[deceased.cemetery]}
          isLoadingGeoInfo={isLoadingGeoInfo}
          cemeteryBorders={cemeteryBoundaries[deceased.cemetery]}
          kioskMode={kioskMode}
        />
        )}
        {kioskMode && <BackButton />}
      </div>
    );
  }
}

DetailComponent.propTypes = {
  id: PropTypes.string.isRequired,
  kioskMode: PropTypes.bool,
};

DetailComponent.defaultProps = {
  kioskMode: false,
};

const BackButton = () => (
  <div className="mt-4 mx-auto" style={{ maxWidth: '30rem' }}>
    <button
      className="btn btn-primary mb-3"
      type="button"
      style={{ width: '100%' }}
      onClick={() => {
        window.history.back();
      }}
    >
      Zurück
    </button>
  </div>
);

const NoLocationDetailText = ({ cemetery }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    axios.request(`https://verstorbenensuche.cloud.bs.ch/notfound-${cemetery}.txt`)
      .then((res) => {
        setText(res.data);
      });
  });

  if (text === '') {
    return null;
  }
  return (
    <div className="alert alert-primary" role="alert">
      {text}
    </div>
  );
};

NoLocationDetailText.propTypes = {
  cemetery: PropTypes.string.isRequired,
};

const DetailCard = ({ deceased, kioskMode }) => (
  <div className="card mt-4 mx-auto" style={{ maxWidth: '30rem' }}>
    <div className="card-body">
      <h3
        className="card-title"
      >
        {deceased.name}
        {' '}
        {deceased.birthname}
        {' '}
        {deceased.additional_surname}
      </h3>
      <p
        className="card-text"
      >
        {deceased.birthdate ? `*${deceased.birthdate},` : null}
        {' '}
        {deceased.deathdate ? `\u2020${deceased.deathdate}` : null}
      </p>
      <p className="card-text">
        Bestattet
        {' '}
        {deceased.burialdate && 'am'}
        {' '}
        {deceased.burialdate}
        {' '}
        auf dem
        {' '}
        {cemeteries[deceased.cemetery]}
        {deceased.section && deceased.section.toLowerCase() !== 'sammelgrab' && deceased.section.substring(0, 2).trim().replace(/^0+/, '') && ', Abteilung '}
        {deceased.section && deceased.section.toLowerCase() !== 'sammelgrab' && deceased.section.substring(0, 2).trim().replace(/^0+/, '')}
        {deceased.section && deceased.section.toLowerCase() !== 'sammelgrab' && deceased.section.substring(2) !== '' ? `, Sektion ${deceased.section.substring(2).trim().replace(/^0+/, '')}` : null}
        {deceased.gravenumber && deceased.section.toLowerCase() !== 'sammelgrab' && `, Grab ${deceased.gravenumber.trim().replace(/^0+/, '')}`}
        {deceased.gravenumber && deceased.section.toLowerCase() === 'sammelgrab' && `, ${deceased.gravenumber.trim()}`}
        .
      </p>
      {!deceased.section && !deceased.gravenumber
        ? <NoLocationDetailText cemetery={deceased.cemetery} /> : null}
    </div>
    <div className="mr-2 ml-2 mb-2 mt-3">
      {kioskMode || (
      <div style={{ position: 'absolute', left: '0.5rem', bottom: '0.5rem' }}>
        <EmailShareButton url={window.location.href}>
          <EmailIcon size={32} borderRadius={5} />
        </EmailShareButton>
        <WhatsappShareButton url={window.location.href} style={{ marginLeft: '5px' }}>
          <WhatsappIcon size={32} borderRadius={5} />
        </WhatsappShareButton>
      </div>
      )}
      <div className="d-none d-md-block d-lg-block d-xl-block" style={{ float: 'right' }}>
        <QRCode
          value={kioskMode
            ? window.location.href.replace(/kiosk=[a-z]+/i, '').replace(/localcemetery=[a-z0-9]+/i, '').replaceAll(/[?&]*/g, '')
            : window.location.href}
          renderAs="svg"
          size={80}
          level="L"
        />
      </div>
    </div>
  </div>
);

DetailCard.propTypes = {
  deceased: PropTypes.instanceOf(Object).isRequired,
  kioskMode: PropTypes.bool,
};

DetailCard.defaultProps = {
  kioskMode: false,
};

const LocationComponent = ({ map, updateDeviceCoordinates, onDeviceCoordinateError }) => {
  const [isLocationControllerInitialized, setIsLocationControllerInitialized] = useState(false);
  const [locationEventHistory, setLocationEventHistory] = useState([]);
  const [locationController] = useState(new Locate({
    onLocationError: onDeviceCoordinateError,
    showPopup: false,
    setView: false,
    locateOptions: { enableHighAccuracy: true, watch: true, maximumAge: 30000 },
  }));

  useEffect(() => {
    if (!isLocationControllerInitialized) {
      map.on('locationfound', (evt) => {
        const lastEvt = locationEventHistory[locationEventHistory.length - 1];
        if (evt.accuracy < 100000 && (!lastEvt
          || lastEvt !== evt
          || (evt.timestamp - lastEvt.timestamp) > 60000
          || distance([evt.latlng.lat, evt.latlng.lng], [lastEvt.latlng.lat, lastEvt.latlng.lng])
          > 0.01)) {
          const evtHist = locationEventHistory;
          evtHist.push(evt);
          setLocationEventHistory(evtHist);
          updateDeviceCoordinates(evt);
        }
      });
      setIsLocationControllerInitialized(true);
      locationController.addTo(map);
    }
    locationController.start();
    return () => {
      locationController.stop();
    };
  }, [
    isLocationControllerInitialized,
    map,
    updateDeviceCoordinates,
    locationEventHistory,
    locationController,
  ]);

  return null;
};

const GraveMap = ({
  coordinates,
  sectionGeometry,
  graveGeometry,
  initialZoomLevel,
  isLoadingGeoInfo,
  cemeteryBorders,
  kioskMode,
}) => {
  const [deviceCoordinates, setDeviceCoordinates] = useState({});
  const [routeGeometry, setRouteGeometry] = useState([]);

  useEffect(() => {
    const routingCoordinates = coordinates;
    if (deviceCoordinates.latlng && deviceCoordinates.latlng.lat && deviceCoordinates.latlng.lng) {
      const deviceCoordArr = [deviceCoordinates.latlng.lat, deviceCoordinates.latlng.lng];
      if (booleanPointInPolygon(point(deviceCoordArr.slice().reverse()),
        polygon([cemeteryBorders]))) {
        // if device coordinates are inside of the cemetery boundary, replace cemetery
        // entry coordinates with device coordinates
        routingCoordinates[0] = deviceCoordArr;
      }
    }

    if (routingCoordinates.length > 1) {
      let coordinateString = '';
      routingCoordinates.forEach((coordinateArr) => {
        coordinateString = `${coordinateString + coordinateArr[1]},${coordinateArr[0]};`;
      });
      const routeURL = `https://routing.openstreetmap.de/routed-foot/route/v1/driving/${coordinateString.slice(0, -1)}?overview=full&geometries=geojson`;
      axios.get(routeURL).then((res) => {
        if (res.data.routes && res.data.routes[0]
          && res.data.routes[0].geometry && res.data.routes[0].geometry.coordinates) {
          setRouteGeometry(res.data.routes[0].geometry.coordinates
            .map((actCoordinates) => actCoordinates.reverse()));
        }
      });
    }
  }, [coordinates, deviceCoordinates, sectionGeometry, cemeteryBorders]);

  return (
    <div className="card mt-4 mx-auto" style={{ maxWidth: '30rem' }}>
      {coordinates.length > 0
      && (
      <div className="card-body">
        {kioskMode || (
          <button
            className="btn btn-primary mb-3"
            type="button"
            style={{ width: '100%' }}
            onClick={() => {
              const mapURL = `https://www.google.com/maps/dir/?api=1&destination=${coordinates[0][0]},${coordinates[0][1]}`;
              window.open(mapURL, '_blank');
            }}
          >
            Route zum Friedhof anzeigen
          </button>
        )}
        <MapContainer center={coordinates[coordinates.length - 1]} zoom={initialZoomLevel} style={{ height: '30rem' }} maxZoom="24">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://wmts.geo.bs.ch/wmts/1.0.0/OF_TrueOrthofoto2020Maerz/default/3857/{z}/{y}/{x}.png"
            maxZoom="24"
          />
          {isLoadingGeoInfo || <Marker position={coordinates[coordinates.length - 1]} />}
          <MapConsumer>
            {(map) => {
              LocationComponent({
                map,
                updateDeviceCoordinates: setDeviceCoordinates,
                onDeviceCoordinateError: () => {},
              });
              return null;
            }}
          </MapConsumer>
          <Polyline positions={routeGeometry} pathOptions={{ color: 'red' }} />
          {graveGeometry.length > 0
            ? <Polygon positions={graveGeometry} pathOptions={{ color: 'red' }} />
            : <Polygon positions={sectionGeometry} pathOptions={{ color: 'green' }} />}
        </MapContainer>
      </div>
      )}
    </div>
  );
};

GraveMap.propTypes = {
  coordinates: PropTypes.instanceOf(Array).isRequired,
  sectionGeometry: PropTypes.instanceOf(Array),
  graveGeometry: PropTypes.instanceOf(Array),
  initialZoomLevel: PropTypes.number,
  isLoadingGeoInfo: PropTypes.bool.isRequired,
  cemeteryBorders: PropTypes.instanceOf(Array),
  kioskMode: PropTypes.bool,
};

GraveMap.defaultProps = {
  sectionGeometry: [],
  initialZoomLevel: 15,
  graveGeometry: [],
  cemeteryBorders: [],
  kioskMode: false,
};
