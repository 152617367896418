import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { cemeteries } from './Cemeteries';

const displayYears = (() => {
  const years = [''];
  let i;
  for (i = new Date().getFullYear(); i >= 1800; i -= 1) {
    years.push(i);
  }
  return years;
})();

const validationSchema = yup.object().shape({
  surname: yup.string()
    .required('Diese Eingabe wird benötigt')
    .matches(/[a-zA-ZÄäÜüÖöÉéÈè]{2}/, 'Bitte mindestens zwei Buchstaben eingeben'),
  name: yup.string()
    .matches(/[a-zA-ZÄäÜüÖöÉéÈè]{2}/, {
      message: 'Bitte mindestens zwei Buchstaben eingeben',
      excludeEmptyString: true,
    }),
  last_address: yup.string()
    .matches(/[a-zA-Z]{2}/, {
      message: 'Bitte mindestens zwei Buchstaben eingeben',
      excludeEmptyString: true,
    }),
});

const SearchComponent = ({
  handleSearchChange, formValues, searching, hasMore, searchResults, noResults, localCemeteryID,
}) => (
  <div>
    <SearchForm
      onNewResults={handleSearchChange}
      onSubmit={handleSearchChange}
      formValues={formValues}
      localCemeteryID={localCemeteryID}
    />
    {searching ? <SearchingIndicator /> : null}
    {hasMore ? <HasMoreAlert /> : null}
    {noResults ? <NoResultsAlert /> : null}
    {searchResults.length > 0 && !searching
      ? <SearchResults searchResults={searchResults} /> : null}
  </div>
);

SearchComponent.propTypes = {
  noResults: PropTypes.bool.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object).isRequired,
  searching: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  searchResults: PropTypes.instanceOf(Array).isRequired,
  localCemeteryID: PropTypes.number,
};

SearchComponent.defaultProps = {
  localCemeteryID: undefined,
};

export default SearchComponent;

const SearchResults = ({ searchResults }) => {
  const renderSearchResults = (results) => results.map((result) => (
    <tr key={result.id}>
      <td>{result.birthname}</td>
      <td>{result.additional_surname}</td>
      <td>{result.name}</td>
      <td>{result.birthdate}</td>
      <td>{result.deathdate}</td>
      <td>{result.burialdate}</td>
      <td>{cemeteries[result.cemetery]}</td>
      <td>{result.section && result.section.toLowerCase() !== 'sammelgrab' && result.section.substring(0, 2).trim().replace(/^0+/, '')}</td>
      <td>{result.section && result.section.toLowerCase() !== 'sammelgrab' && result.section.substring(2).trim().replace(/^0+/, '')}</td>
      <td>{result.gravenumber && result.gravenumber.trim().replace(/^0+/, '')}</td>
      <td>
        <Link to={`/${result.id}`}>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-zoom-in"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
            />
            <path
              d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"
            />
            <path
              fillRule="evenodd"
              d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
        </Link>
      </td>
    </tr>
  ));

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover table-sm table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Allianzname</th>
              <th>Vorname</th>
              <th>Geburtsdatum</th>
              <th>Todesdatum</th>
              <th>Bestattungsdatum</th>
              <th>Friedhof</th>
              <th>Abteilung</th>
              <th>Sektion</th>
              <th>Grabnummer</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {renderSearchResults(searchResults)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.instanceOf(Array).isRequired,
};

const SearchingIndicator = () => {
  const [isShowingIndicator, setIsShowingIndicator] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowingIndicator(true);
    }, 500);
  });

  return isShowingIndicator ? <div className="text-center"><Spinner animation="border" /></div> : null;
};

const HasMoreAlert = () => (
  <div className="alert alert-primary" role="alert">
    Es konnten nicht alle Suchresultate angezeigt
    werden. Bitte schränken Sie die Kriterien ein, um alle
    entsprechenden Resultate zu erhalten.
  </div>
);

const NoResultsAlert = () => (
  <div className="alert alert-primary" role="alert">
    Person nicht gefunden.
  </div>
);

const SearchForm = ({
  onSubmit, onNewResults, formValues, localCemeteryID,
}) => {
  const [cemeteryDefaultValue, setCemeteryDefaultValue] = useState('');
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formValues.cemetery) {
      setCemeteryDefaultValue(formValues.cemetery);
    } else if (localCemeteryID) {
      const selectedCemeteryKey = Object.keys(cemeteries)
        .find((actCemeteryID) => parseInt(actCemeteryID, 10) === localCemeteryID);
      if (selectedCemeteryKey) {
        setCemeteryDefaultValue(selectedCemeteryKey);
      }
    }
  }, [localCemeteryID, formValues, cemeteryDefaultValue]);

  const submit = (values) => {
    onSubmit({
      searchResults: [],
      hasMore: false,
      searching: true,
      formValues: values,
    });
    const submitVals = { ...values };
    Object.keys(submitVals).forEach((k) => (submitVals[k] == null || submitVals[k] === '') && delete submitVals[k]);
    if (submitVals.name) {
      submitVals.name += '*';
    }
    axios.request({
      url: 'https://api.verstorbenensuche.cloud.bs.ch/deceased',
      method: 'get',
      params: submitVals,
    }).then((res) => {
      onNewResults({
        searchResults: res.data.deceased,
        hasMore: res.data.hasmore,
        searching: false,
        noResults: res.data.deceased.length === 0,
      });
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <Row>
          <Col>
            {errors.surname && errors.surname.message}
            <InputField
              fieldname="surname"
              fieldlabel="Name (* als Platzhalter verwendbar)"
              register={register}
              defaultValue={formValues.surname}
            />
          </Col>
          <Col>
            {errors.name && errors.name.message}
            <InputField
              fieldname="name"
              fieldlabel="Vorname (* als Platzhalter verwendbar)"
              register={register}
              defaultValue={formValues.name}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <SelectField
              fieldname="min_deathyear"
              fieldlabel="Todesjahr von"
              optionValues={displayYears}
              register={register}
              defaultValue={formValues.min_deathyear}
            />
          </Col>
          <Col xs="6">
            <SelectField
              fieldname="max_deathyear"
              fieldlabel="Todesjahr bis"
              optionValues={displayYears}
              register={register}
              defaultValue={formValues.max_deathyear}
            />
          </Col>
          <Col xs="6">
            <SelectField
              fieldname="min_birthyear"
              fieldlabel="Geburtsjahr von"
              optionValues={displayYears}
              register={register}
              defaultValue={formValues.min_birthyear}
            />
          </Col>
          <Col xs="6">
            <SelectField
              fieldname="max_birthyear"
              fieldlabel="Geburtsjahr bis"
              optionValues={displayYears}
              register={register}
              defaultValue={formValues.max_birthyear}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" lg="6">
            <SelectField
              fieldname="cemetery"
              fieldlabel="Friedhof"
              optionValues={Object.entries(cemeteries)}
              register={register}
              defaultValue={cemeteryDefaultValue}
            />
          </Col>
        </Row>
        <button className="btn btn-primary mb-3" type="submit">Suche</button>
      </form>
    </div>
  );
};

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onNewResults: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object).isRequired,
  localCemeteryID: PropTypes.number,
};

SearchForm.defaultProps = {
  localCemeteryID: undefined,
};

const SelectField = ({
  error, fieldlabel, fieldname, register, defaultValue, optionValues,
}) => {
  function getOptions(vals) {
    if (Array.isArray(vals[0])) {
      return vals.map(([k, v]) => {
        let defaultVal = false;
        if (k === defaultValue) {
          defaultVal = true;
        }
        return <option key={k} value={k} selected={defaultVal}>{v}</option>;
      });
    }
    return vals.map((v) => {
      let defaultVal = false;
      if (v === defaultValue) {
        defaultVal = true;
      }
      return <option key={v} value={v} selected={defaultVal}>{v}</option>;
    });
  }

  return (
    <div className="form-group">
      {error ? <div>{error}</div> : null}
      <label htmlFor={fieldname}>{fieldlabel}</label>
      <select
        className="form-control"
        name={fieldname}
        id={fieldname}
        ref={register}
        defaultValue={defaultValue}
      >
        {getOptions(optionValues)}
      </select>
    </div>
  );
};

SelectField.propTypes = {
  error: PropTypes.string,
  fieldlabel: PropTypes.string.isRequired,
  fieldname: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  optionValues: PropTypes.instanceOf(Array).isRequired,
};

SelectField.defaultProps = {
  error: '',
  defaultValue: '',
};

const InputField = ({
  error, fieldname, fieldlabel, register, defaultValue,
}) => (
  <div className="form-group">
    {error ? <div>{error}</div> : null}
    <label htmlFor={fieldname}>{fieldlabel}</label>
    <input
      type="text"
      className="form-control"
      name={fieldname}
      id={fieldname}
      ref={register}
      defaultValue={defaultValue}
    />
  </div>
);

InputField.propTypes = {
  error: PropTypes.string,
  fieldlabel: PropTypes.string.isRequired,
  fieldname: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

InputField.defaultProps = {
  error: '',
  defaultValue: '',
};
